<template>
    <video-hero-section
        class="video-hero-section-module"
        :title="attributes.videoHeroSection_title"
        :video="desktopVideo"
        :mobile-video="mobileVideo"
        :button-text="attributes.button_text"
        :button-link="buttonLink"
    />
</template>

<script>
    import formatMediaObject from '../../../../utils/formatters/format-media-object';
    import VideoHeroSection from '../../../organisms/VideoHeroSection/VideoHeroSection';

    export default {
        name: 'video-hero-section-module',

        components: { VideoHeroSection },

        props: {
            attributes: {
                type: Object,
                required: true,
                default: () => ({}),
            },
        },

        computed: {
            /**
             * Map video into video hero format.
             *
             * @returns {object}
             */
            desktopVideo() {
                return formatMediaObject.call(this, this.attributes.video);
            },

            /**
             * Map video into video hero format.
             *
             * @returns {object}
             */
            mobileVideo() {
                return formatMediaObject.call(this, this.attributes.mobile_video);
            },

            /**
             * Return either the button link or the external link.
             *
             * @returns {string}
             */
            buttonLink() {
                return (this.attributes.button_link?.data?.attributes?.uri || this.attributes.button_link_external || '')
                    .replace(this.$config.APP_URL, '');
            },
        },
    };
</script>
