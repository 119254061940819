<template>
    <div
        class="video-hero"
        :style="{
            aspectRatio: activeVideo && activeVideo.width && activeVideo.height
                ? `${ activeVideo.width } / ${ activeVideo.height }`
                : undefined,
        }"
    >
        <video
            v-if="activeVideo"
            :key="activeVideo.src"
            :style="{
                aspectRatio: activeVideo.width && activeVideo.height
                    ? `${ activeVideo.width } / ${ activeVideo.height }`
                    : undefined,
            }"
            :width="activeVideo.width"
            :height="activeVideo.height"
            autoplay
            muted
            loop
            disablePictureInPicture
            playsinline
            ref="video"
            v-test:video
        >
            <source
                :src="activeVideo.src"
                :type="activeVideo.type"
                v-test:source
            >
            Sorry, your browser doesn't support embedded videos.
            <a
                :href="activeVideo.src"
                target="_blank"
                rel="noopener"
            >View video in separate tab</a>
        </video>

        <div
            v-if="buttonLink && buttonText"
            class="button-cta-container"
        >
            <b-button
                class="button-cta"
                variant="primary"
                v-bind="ctaProps"
            >
                {{ buttonText }}
            </b-button>
        </div>

        <div class="volume-control">
            <b-button
                class="volume-control-button"
                variant="primary"
                @click.prevent="states.muted = !states.muted"
            >
                <span class="sr-only">
                    {{ states.muted ? 'Unmute' : 'Mute' }} video
                </span>

                <app-icon :name="states.muted ? 'muted' : 'unmuted'" />
            </b-button>
        </div>

        <global-events
            target="window"
            @resize="setVideoSource"
        />
    </div>
</template>

<script>
    import GlobalEvents from 'vue-global-events';

    export default {
        name: 'video-hero',

        components: { GlobalEvents },

        props: {
            video: {
                type: Object,
                required: true,
            },

            mobileVideo: {
                type: Object,
                default: () => ({}),
            },

            buttonText: {
                type: String,
                default: '',
            },

            buttonLink: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                activeVideo: this.video,
                states: {
                    muted: true,
                },
            };
        },

        computed: {
            /**
             * Return the props to apply to the cta.
             *
             * @returns {{to: string}|{rel: string, href: string, target: string}}
             */
            ctaProps() {
                if (this.buttonLink.startsWith('/')) {
                    return {
                        to: this.buttonLink,
                    };
                }

                return {
                    href: this.buttonLink,
                    target: '_blank',
                    rel: 'noopener',
                };
            },
        },

        watch: {
            /**
             * Sync the muted state up with the video element.
             *
             * @param {boolean} muted
             */
            'states.muted'(muted) {
                this.$refs.video.muted = muted;
            },
        },

        mounted() {
            this.setVideoSource();
        },

        methods: {
            /**
             * Set the video source based on viewport.
             */
            setVideoSource() {
                if (window.innerWidth < 768 && this.mobileVideo?.src) {
                    this.activeVideo = this.mobileVideo;
                    return;
                }

                this.activeVideo = this.video;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .video-hero {
        position: relative;

        &:hover,
        &:focus-within {
            .btn {
                opacity: 1;
            }
        }
    }

    video {
        width: 100%;
        height: 100%;
        display: block;
    }

    .volume-control {
        position: absolute;
        right: 10px;
        bottom: 10px;

        @include media-breakpoint-up(md) {
            right: 20px;
            bottom: 20px;
        }
    }

    .volume-control-button {
        padding: 0;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 0;
        opacity: 0.5;
        transition: 0.15s;
        z-index: 2;
    }

    .button-cta-container {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding-bottom: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        @include media-breakpoint-up(md) {
            padding-bottom: 5%;
        }
    }

    .button-cta {
        min-width: 140px;
    }
</style>
