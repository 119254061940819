<template>
    <fade-up-animation :element="() => $refs.section">
        <section class="video-hero-section" ref="section">
            <h1
                v-if="title"
                class="sr-only"
                v-test:title
            >
                {{ title }}
            </h1>

            <video-hero
                :video="video"
                :mobile-video="mobileVideo"
                :button-text="buttonText"
                :button-link="buttonLink"
                v-test:videoHero
            />
        </section>
    </fade-up-animation>
</template>

<script>
    import VideoHero from '../../molecules/VideoHero/VideoHero';

    export default {
        name: 'video-hero-section',

        components: { VideoHero },

        props: {
            title: {
                type: String,
                default: '',
            },

            ...VideoHero.props,
        },
    };
</script>
