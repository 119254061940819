var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"video-hero",style:({
        aspectRatio: _vm.activeVideo && _vm.activeVideo.width && _vm.activeVideo.height
            ? ((_vm.activeVideo.width) + " / " + (_vm.activeVideo.height))
            : undefined,
    })},[(_vm.activeVideo)?_c('video',{directives:[{name:"test",rawName:"v-test:video",arg:"video"}],key:_vm.activeVideo.src,ref:"video",style:({
            aspectRatio: _vm.activeVideo.width && _vm.activeVideo.height
                ? ((_vm.activeVideo.width) + " / " + (_vm.activeVideo.height))
                : undefined,
        }),attrs:{"width":_vm.activeVideo.width,"height":_vm.activeVideo.height,"autoplay":"","muted":"","loop":"","disablePictureInPicture":"","playsinline":""},domProps:{"muted":true}},[_c('source',{directives:[{name:"test",rawName:"v-test:source",arg:"source"}],attrs:{"src":_vm.activeVideo.src,"type":_vm.activeVideo.type}}),_vm._v("\n        Sorry, your browser doesn't support embedded videos.\n        "),_c('a',{attrs:{"href":_vm.activeVideo.src,"target":"_blank","rel":"noopener"}},[_vm._v("View video in separate tab")])]):_vm._e(),_vm._v(" "),(_vm.buttonLink && _vm.buttonText)?_c('div',{staticClass:"button-cta-container"},[_c('b-button',_vm._b({staticClass:"button-cta",attrs:{"variant":"primary"}},'b-button',_vm.ctaProps,false),[_vm._v("\n            "+_vm._s(_vm.buttonText)+"\n        ")])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"volume-control"},[_c('b-button',{staticClass:"volume-control-button",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();_vm.states.muted = !_vm.states.muted}}},[_c('span',{staticClass:"sr-only"},[_vm._v("\n                "+_vm._s(_vm.states.muted ? 'Unmute' : 'Mute')+" video\n            ")]),_vm._v(" "),_c('app-icon',{attrs:{"name":_vm.states.muted ? 'muted' : 'unmuted'}})],1)],1),_vm._v(" "),_c('global-events',{attrs:{"target":"window"},on:{"resize":_vm.setVideoSource}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }